<template>
  <div class="">
    <div v-if="hasBets" class="grid grid-cols-1">
      <BetsListItem v-for="bet in bets" :key="bet.id" :bet="bet" @click="this.$router.push(`/bets/${bet.id}`)" />
    </div>
    <div v-else>
      <slot name="empty">
        There are no bets
      </slot>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BetsListItem from '@/components/Bets/BetsListItem'

export default {
  name: 'BetsList',
  props: {
      guildId: {
          type: String,
          default: null
      },
      status: {
        type: String,
        default: 'open'
      },
      sort: {
        type: String,
        default: 'desc'
      }
  },
  data: () => ({
    bets: []
  }),
  computed: {
    hasBets: function() {
      return this.bets.length > 0
    }
  },
  components: {
    BetsListItem
  },
  methods: {
    load: function() {
      this.$api.get(`/bets?filter[guildId]=${this.guildId}&filter[status]=${this.status}&sort=${this.sort}`).then(result => this.bets = result.data);
    },

  },
  watch: {
    guildId: function(newVal, oldVal) {
      if (newVal!=oldVal) this.load();
    }
  },
  mounted: function() {
    this.load();
  }
}
</script>
