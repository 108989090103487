import pusher from '@/lib/pusherConnector'

export default {
    namespaced: true,
    state: {
      pusherChannel: null
    },
    mutations: {
      setPusherChannel(state, payload) {
          state.pusherChannel = payload;
      }
    },
    getters: {
      
    },
    actions: {
        connect: async function(context, payload) {
            console.log('bet/pusher/connect', payload);
            if (context.state.pusherChannel) {
                //await pusher.unsubscribe(context.state.pusherChannel).then(() => context.commit('setPusherChannel', null))
            }
            let channel = pusher.subscribe(`bet-${payload}`);
            channel.bind('entry_create', (data, metadata) => context.dispatch('onEntryCreate', {
                data,
                metadata
            }))
            channel.bind('entry_update', (data, metadata) => context.dispatch('onEntryUpdate', {
                data,
                metadata
            }))
            channel.bind('entry_delete', (data, metadata) => context.dispatch('onEntryDelete', {
                data,
                metadata
            }))
            context.commit('setPusherChannel', channel);
        },
        onEntryCreate: async function(context, payload) {
            context.commit('bet/addEntry', payload.data, { root: true })
        },
        onEntryUpdate: async function(context, payload) {
            context.commit('bet/updateEntry', payload.data, { root: true })
        },
        onEntryDelete: async function(context, payload) {
            context.commit('bet/removeEntry', payload.data, { root: true })
        },
        
    }
  }
  