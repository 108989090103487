<template>
  <select v-model="value" @change="$emit('update:modelValue', value)">
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: "LevelSelect",
  props: ["modelValue"],
  data: () => ({
    value: null,
    options: [
      { value: "admin", label: "admin" },
      { value: "moderator", label: "moderator" },
      { value: "user", label: "user" },
    ],
  }),
  mounted() {
    this.value = this.modelValue;
  },
};
</script>
