<template>
  <div class="border border-gray-400 p-2 relative mb-2 p-4">
    <div>
      <div
        v-if="guild.privacy != 'public'"
        class="text-sm border rounded h-6 px-2 float-right bg-yellow-100 border-yellow-200 text-yellow-600"
      >
        {{ guild.privacy }}
      </div>
      <div class="text-left text-xl">{{ guild.name }}</div>
    </div>
    <div v-if="guild.archivedAt" class="text-xs">
      Archived on {{ archivedAt }}
    </div>
    <div v-else class="text-xs">{{ openBets }}</div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "GuildsListItem",
  props: {
    guild: Object,
  },
  data: () => ({
    auth: null,
  }),
  computed: {
    openBets: function () {
      let cnt = this.guild.openBetCount;
      if (cnt < 1) return "no open bets";
      else return `${cnt} open bet${cnt == 1 ? "" : "s"}`;
    },
    archivedAt: function () {
      if (this.guild.archivedAt)
        return moment(this.guild.archivedAt).format("DD. MM. YYYY");
      else return null;
    },
  },
  methods: {},
};
</script>
