<template>
    <span :class="style">
        <slot></slot>
    </span>

</template>

<script>
 
export default {
  name: 'UiButton',
  props: {
    block: {
        type: Boolean,
        default: false
    },
    color: {
        type: String,
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    },
    to: {
        type: String,
        default: null
    }
  },
  data: () => ({
      
  }),
  computed: {
      style: function() {
          let classes = [
            'text-center',
            this.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            this.block ? 'block' : 'inline',
            'border',
            'rounded-md',
            'p-2',
            ];
            if (this.color) {
                classes.push(`bg-${this.color}`)
            }
          
          return classes.join(' ');
      }
  }
}
</script>