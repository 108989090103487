<template>
  <div>
    <div class="mb-2">
      <label class="block font-bold">Group name</label>
      <input
        class="w-full"
        type="text"
        v-model="name"
        placeholder="Group name"
      />
    </div>
    <div class="mb-2">
      <label class="block font-bold">Privacy</label>
      <select v-model="privacy">
        <option
          v-for="option in privacyOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
    </div>
    <div class="mt-4">
      <UiButton @click="save()" color="green-400">Update</UiButton>
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/Ui/UiButton.vue";
// @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "GuildsEditForm",
  data: () => ({
    name: null,
    privacy: "public",
    privacyOptions: [
      { value: "public", name: "Public" },
      { value: "unlisted", name: "Unlisted (only people with link)" },
      { value: "private", name: "Private (invite only)" },
    ],
  }),
  computed: {
    ...mapState("guild", {
      guild: (state) => state.guild,
    }),
  },
  methods: {
    loadForm: function () {
      if (this.guild) {
        this.name = this.guild.name;
        this.privacy = this.guild.privacy;
      }
    },
    save: function () {
      this.$store.dispatch("guild/update", {
        name: this.name,
        privacy: this.privacy,
      });
    },
  },
  components: { UiButton },
  watch: {
    guild: function () {
      this.loadForm();
    },
  },
  mounted: function () {
    this.loadForm();
  },
};
</script>
