import { get, post, patch, del } from '@/lib/apiConnector'

import pusher from './pusher'

export default {
    namespaced: true,
    modules: {
        pusher
    },
    state: {
      bet: null,
    },
    mutations: {
      setBet(state, payload) {
          state.bet = payload;
      },
      addEntry(state, payload) {
        if (state.bet && state.bet.id) {
            if (!state.bet.entries) {
                state.bet.entries = [];
            }
            let entry = state.bet.entries.find(e => e.id == payload.id);
            if (!entry)
                state.bet.entries.push(payload);
        }
      },
      updateEntry(state, payload) {
        if (state.bet && state.bet.entries && state.bet.entries.length > 0) {
            let index = state.bet.entries.findIndex(e => e.user.id == payload.userId);
            if (index>=0) state.bet.entries[index] = payload;
        }
      },
      removeEntry(state, payload) {
        if (state.bet && state.bet.entries && state.bet.entries.length > 0) {
            
            let index = state.bet.entries.findIndex(e => e.userId == payload.userId || e.user?.id == payload.userId);
            if (index>=0) state.bet.entries.splice(index, 1);
        }
      },
      setMyEntry(state, payload) {
          state.bet.myEntry = payload
      }
    },
    getters: {
      canEdit: function(state, getters, rootState) {
          let r = false;

          if (rootState.user.isLoggedIn && state.bet.userId == rootState.user.id) {
              r = true;
          }

          return r;
      }
    },
    actions: {
        load: async function(context, payload) {
            let betId = payload;
            let force = false;
            if (typeof payload === 'object') {
                betId = payload.id;
                force = payload.force;
            }

            context.commit('setBet', null)
            return get(`/bets/${betId}`).then(response => {
                let bet = response.data;
                context.commit('setBet', bet)
                if (bet.guildId)
                {
                    context.dispatch('guild/load', { id: bet.guildId, force: force }, {root: true})
                }

                if (['waiting', 'active'].indexOf(bet.status)>-1) {
                    context.dispatch('pusher/connect', bet.id)
                }

                return bet;
                    
            })
        },
        entry: function(context, payload) {
            post(`/bets/${context.state.bet.id}/entries`, {
                option: payload
            })
            .then(response => response.data)
            .then(entry => {
                context.commit('addEntry', entry)
                context.commit('setMyEntry', entry);
                context.dispatch('user/load', null, {root: true});
            });
        },
        changeEntry: function(context, payload) {
            patch(`/bets/${context.state.bet.id}/entries`, {
                option: payload
            })
            .then(response => response.data)
            .then(entry => {
                console.log(entry)
                context.commit('updateEntry', entry)
                context.commit('setMyEntry', entry);
                context.dispatch('user/load', null, {root: true});
            });
        },
        deleteEntry: function(context) {
            del(`/bets/${context.state.bet.id}/entries`)
            .then(response => response.data)
            .then((entry) => {
                context.commit('removeEntry', entry)
                context.commit('setMyEntry', null);
                context.dispatch('user/load', null, {root: true});
            });
        },
        finish: function(context, payload) {
            post(`/bets/${context.state.bet.id}/finish`, {
                finalOption: payload
            }).then(() => {
                context.dispatch('load', context.state.bet.id)
            });
        },
        unfinish: function(context) {
            post(`/bets/${context.state.bet.id}/unfinish`).then(() => {
                context.dispatch('load', context.state.bet.id)
            });
        }
        
    }
  }
  