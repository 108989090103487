
import store from '../store'

import { get, post } from '@/lib/apiConnector'



function loaderInc() {
    store.commit('loader/inc');
}

function loaderDec() {
    store.commit('loader/dec');
}

export default {
    // called by Vue.use(FirstPlugin)
    install(app) {

        app.config.globalProperties.$loader = {
            inc: () => loaderInc(),
            dec: () => loaderDec()
        }

        app.config.globalProperties.$api = {
            get, post
        }
    }
 }