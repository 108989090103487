<template>
    <div class="border">
        <h2 v-if="label" :class="`bg-gray-200 px-4 py-2 font-bold bg-${color}`">{{ label }}</h2>
        <div :class="contentClass">

          <slot></slot>

        </div>
      </div> 

</template>

<script>
 
export default {
  name: 'UiPanel',
  props: {
    
    label: {
        type: String,
        default: null
    },
    color: {
        type: String,
        default: 'gray-200'
    },
    flex : {
      type: Boolean,
      default: true
    },
    noGutters: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
      
  }),
  computed: {
      contentClass: function() {
        let classes = [];
        if (!this.noGutters) classes.push('p-4');
        if (this.flex) classes.push('flex');
        return classes.join(' ')
      }
  }
}
</script>