<template>
  <div>
    <p class="text-red-600 font-bold">
      WARNING! When you archive the group, you can't add any new bets. This
      change is irreversible.
    </p>
    <p>Type ARCHIVE in the input below to confirm archivation of this group.</p>
    <input
      type="text"
      v-model="archiveConfirm"
      placeholder="Please confirm archivation"
      class="my-2 w-full"
    />
    <UiButton
      block
      color="red-600"
      class="text-white font-bold"
      :disabled="!enableArchive"
      @click="archive"
    >
      Archive group
    </UiButton>
  </div>
</template>

<script>
// @ is an alias to /src
import UiButton from "@/components/Ui/UiButton.vue";

export default {
  name: "GuildsArchive",

  data: () => ({
    archiveConfirm: null,
  }),
  computed: {
    enableArchive: function () {
      return this.archiveConfirm === "ARCHIVE";
    },
  },
  components: {
    UiButton,
  },
  methods: {
    archive: function () {
      if (this.enableArchive)
        this.$store.dispatch("guild/update", {
          isArchived: true,
        });
    },
  },
};
</script>
