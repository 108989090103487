export default {
  namespaced: true,
  state: {
    active: 0,
    total: 0,
  },
  mutations: {
    inc(state) {
        state.active++;
    },
    dec(state) {
        state.total++;
    },
  },
  getters: {
    progress: function(state) {
        let left = state.active - state.total;
        if (left>0) {
            return 100 / (left+1);
        }
        return null;
    }
  }
}
