<template>
  <div class="home">
    <div
      v-if="guildPrivate"
      class="m-4 p-4 border rounded bg-red-100 border-red-200 font-bold"
    >
      Access denied!
      <div style="width: 100%">
        <div
          style="
            height: 0;
            padding-bottom: 56.25%;
            position: relative;
            width: 100%;
          "
        >
          <iframe
            allowfullscreen=""
            frameBorder="0"
            height="100%"
            src="https://giphy.com/embed/G2KRbsTTjuEEmuVSpD/video"
            style="left: 0; position: absolute; top: 0"
            width="100%"
          ></iframe>
        </div>
      </div>
    </div>

    <div v-else>
      <GuildsDetailHeader v-if="guild" :guild="guild" />
      <!--
    <div class="flex p-4 pb-0 border-b-2">
      <div class="grow border-b-4 rounded-t border-green-400 px-8 py-2 font-bold bg-gray-100">Open bets</div>
      <div class="grow px-8 py-2 text-gray-500">Results</div>
      <div class="grow px-8 py-2 text-gray-500">Standings</div>
    </div>
    -->
      <div class="xl:flex mx-4 mb-4">
        <div class="xl:w-3/4 xl:pr-4">
          <UiPanel label="Open bets" class="mt-4" no-gutters>
            <BetsList
              v-if="guild?.id"
              :guild-id="guild.id"
              status="waiting,activew"
              sort="asc"
              class="w-full"
            >
              <template v-slot:empty>
                <div class="p-4 text-center text-gray-300">
                  There are no open bets at this time. Maybe you can create one?
                </div>
              </template>
            </BetsList>
          </UiPanel>

          <UiPanel label="Results" class="mt-4" no-gutters>
            <BetsList
              v-if="guild?.id"
              :guild-id="guild.id"
              status="closed,finished,timedout"
              class="w-full"
            >
              <template v-slot:empty>
                <div class="p-4 text-center text-gray-300">
                  There are no finished bets yet.
                </div>
              </template>
            </BetsList>
          </UiPanel>
        </div>
        <div class="xl:w-1/4">
          <UiPanel label="Standings" class="mt-4">
            <GuildsStanding v-if="guild?.id" />
          </UiPanel>

          <UiPanel label="Awards" class="mt-4">
            <GuildsAchievements v-if="guild?.id" />
          </UiPanel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import BetsList from "@/components/Bets/BetsList.vue";
import moment from "moment";
import GuildsDetailHeader from "../components/Guilds/GuildsDetailHeader.vue";
import { mapState } from "vuex";
import UiPanel from "../components/Ui/UiPanel.vue";
import GuildsStanding from "../components/Guilds/GuildsStanding.vue";
import GuildsAchievements from "../components/Guilds/GuildsAchievements.vue";

export default {
  name: "GuildDetail",
  data: () => ({}),
  components: {
    BetsList,
    GuildsDetailHeader,
    UiPanel,
    GuildsStanding,
    GuildsAchievements,
  },
  computed: {
    ...mapState("guild", {
      guild: (state) => state.guild,
      guildPrivate: (state) => state.guildPrivate,
    }),
    ...mapState("user", {
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    guildSince: function () {
      let m = moment(this.guild.createdAt);
      return m.format("MMMM YYYY");
    },
  },
  methods: {
    load: function (force) {
      let payload = {
        id: this.$route.params.id,
        force: force,
      };
      this.$store.dispatch("guild/load", payload).then((guild) => {
        this.$router.updateMeta({ title: [guild.name] });
      });
    },
  },
  watch: {
    $route: function (newVal) {
      if (newVal.name == "GuildDetail") this.load();
    },
    isLoggedIn: function () {
      console.log("relogged?");
      this.load(true);
    },
  },
  mounted: function () {
    this.load();
  },
};
</script>
