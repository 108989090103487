<template>
    <div class="mb-2">
      <label class="block font-bold" v-if="label">{{ label }}</label>
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'FormRow',
  props: {
      label: {
          type: String,
          default: null
      }
  }
}
</script>