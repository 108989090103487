import { get, post, patch, del } from '@/lib/apiConnector'

import standings from './standings'
import users from './users'
import achievements from './achievements'

export default {
    namespaced: true,
    modules: {
        standings,
        users,
        achievements
    },
    state: {
      guild: null,
      guildPrivate: false,
      
    },
    mutations: {
      setGuild(state, payload) {
          state.guild = payload;
          
          if (payload && payload.id) {
              state.guildPrivate = false;
          }
      },
      updateGuild(state, payload) {
        Object.keys(payload).forEach(key => state.guild[key] = payload[key])
      },
      addGuildUser(state, payload) {
          if (state.guild && !state.guild.guild_users) state.guild.guild_users = [];

          state.guild.guild_users.push(payload);
      },
      removeGuildUser(state) {

        state.guild.guild_users = [];
    },
      setGuildPrivate(state, payload) {
          state.guildPrivate = payload
      },
      updateGuildUser(state, payload) {
          let g = JSON.parse(JSON.stringify(state.guild));
          for (let k of Object.keys(payload)) {
            g.guild_users[0][k] = payload[k];
          }
          state.guild = g;
          
          
      }
    },
    getters: {
      isMember: function(state, getters, rootState) {
            let r = false;
            if (rootState.user.isLoggedIn && state.guild?.guild_users?.find(gu => gu.userId == rootState.user.user.id)) {
                r = true;
            }
            return r;
      },
      isModerator: function(state, getters, rootState) {
          if (rootState.user.isLoggedIn)
          {
            let user = state.guild?.guild_users?.find(gu => gu.userId == rootState.user.user.id)
            
            return (user && ['moderator', 'admin', 'owner'].indexOf(user.level)>-1)
          }
          return false;
      },
      isAdmin: function(state, getters, rootState) {
        if (rootState.user.isLoggedIn)
        {
          let user = state.guild?.guild_users?.find(gu => gu.userId == rootState.user.user.id)
          
          return (user && ['admin', 'owner'].indexOf(user.level)>-1)
        }
        return false;
    },
      hasGuildRole: (state, getters, rootState) => (guildId, role) => {
          let role_hierarchy = ['user', 'moderator', 'admin', 'owner'];
          if (rootState.user.isLoggedIn)
          {
            let user = state.guild?.guild_users?.find(gu => gu.userId == rootState.user.user.id)
            
            if (user)
            {
                console.log({lvl: user.level});
                let level = user.level;
                // owner is chad
                if (level == 'owner') return true;
                if (role_hierarchy.indexOf(level) >= role_hierarchy.indexOf(role)) return true;
                else return false;
                
            }
            
          }
          return false;
          
      }

    },
    actions: {
        follow: function(context, payload) {
            
            post(`/guilds/${payload}/users`).then(response => {
                if (payload == context.state.guild.id)
                    context.commit('addGuildUser', response.data)

                context.dispatch('standings/load');
                context.dispatch('user/load', null, {root: true});
            })
        
            
        },
        unfollow: function(context, payload) {
            let userId = context.rootState.user.user.id;
            context.commit('removeGuildUser');
            del(`/guilds/${payload}/users/${userId}`).then(() => {
                context.dispatch('standings/load');
                context.dispatch('user/load', null, {root: true});
            })
        
            
        },
        load: async function(context, payload) {
            let guildId = payload;
            let force = false;
            if (typeof payload === 'object') {
                guildId = payload.id;
                force = payload.force;
            }
            if (context.state.guild?.id != guildId || force) {
                context.commit('setGuild', {})
                return get(`/guilds/${guildId}`).then(response => {

                    context.commit('setGuild', response.data)
                    context.dispatch('standings/load');

                    return response.data
                }).catch(err => {
                    context.commit('setGuildPrivate', true);
                    console.log(err);
                    return null
                })
            }
            return context.state.guild
        },
        update: function(context, payload) {
            if (context.state.guild?.id) {
                patch(`/guilds/${context.state.guild.id}`, payload).then(response => {
                    context.commit('updateGuild', response.data)
                    if (payload.isArchived) {
                        context.dispatch('user/load', null, {root: true});
                    }
                })
            }
        },
        updateNotify: function(context, payload) {
          let guildId = context.state.guild.id;
          let userId = context.rootState.user.user.id;

          console.log({guildId, userId});

          context.commit('updateGuildUser', {
            notify: payload
          })
          patch(`/guilds/${guildId}/users/${userId}`, {
              notify: payload
            }).then(response => {
              context.commit('updateGuildUser', response.data)
          })
        },
        getUploadUrl: async function(context, payload) {
            let guildId = context.state.guild.id;
            if (guildId) {
                return post(`/guilds/${guildId}/files/s3-url`, payload).then(response => {
                    return response.data;
                })
            }
            return false;
        }
    }
  }
  