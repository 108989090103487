<template>
  <div>
    <div
      v-for="user in filteredUsers"
      :key="user.id"
      class="flex border-b w-full"
    >
      <div class="w-1/2">{{ user.user.display_name }}</div>
      <div>
        <span v-if="user.level == 'owner'">Owner</span>
        <LevelSelect
          v-else
          v-model="user.level"
          @update:modelValue="
            (newValue) =>
              $store.dispatch('guild/users/update', {
                userId: user.userId,
                level: newValue,
              })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import LevelSelect from "../Form/LevelSelect.vue";

export default {
  name: "GuildsUsers",

  data: () => ({
    guilds: [],
  }),
  computed: {
    ...mapState("guild/users", {
      users: (state) => state.users,
    }),
    filteredUsers: function () {
      return this.users.filter((u) => u.level != "owner");
    },
  },
  components: {
    LevelSelect,
  },
  methods: {
    load: function () {
      this.$store.dispatch("guild/users/load", this.$route.params.id);
    },
  },

  mounted: function () {
    this.load();
  },
};
</script>
