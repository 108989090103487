<template>
  <div class="home pb-4">
    <GuildsDetailHeader v-if="guild" :guild="guild" />

    <div v-if="bet && isModerator">
      <div class="flex p-4 pb-0 border-b-2">
        <div class="grow px-8 py-2 bg-gray-100">
          <router-link :to="`/bets/${bet.id}`">&lt; back to bet</router-link>
        </div>
        <div
          class="grow border-b-4 rounded-t border-green-400 px-8 py-2 bg-gray-100 ml-4"
        >
          Moderator panel
        </div>
      </div>
      <div class="mx-4">
        <h1 class="font-bold text-xl my-8 mb-4">{{ bet.subject }}</h1>
        <UiPanel label="Finish bet" :flex="false">
          <div v-if="bet.status == 'finished'">
            Bet finished with option: {{ finalOptionText }}
            <UiButton block color="red-400" class="px-8" @click="unfinish"
              >Unfinish</UiButton
            >
          </div>
          <div v-else>
            <div class="flex flex-row w-full">
              <FormRow label="Select final result" class="w-1/2">
                <select v-model="finalOption" class="w-full">
                  <option value="">choose ...</option>
                  <option value="a">{{ bet.optionA }}</option>
                  <option value="b">{{ bet.optionB }}</option>
                </select>
              </FormRow>

              <div class="ml-4">
                <FormRow label="&nbsp;">
                  <UiButton
                    block
                    color="green-400"
                    class="px-8"
                    :disabled="finalOption == ''"
                    @click="finish"
                    >Save</UiButton
                  >
                </FormRow>
              </div>
            </div>
          </div>
        </UiPanel>

        <UiPanel label="Edit bet" :flex="false" class="mt-4">
          <UiAlert v-if="isLimited"
            >Editing of some fields is disabled, because bet has already
            entries.</UiAlert
          >
          <FormRow label="Subject">
            <input
              v-model="bet_subject"
              :class="
                'w-full ' + (isLimited ? 'bg-gray-200 cursor-not-allowed' : '')
              "
              type="text"
              :disabled="isLimited"
            />
          </FormRow>
          <FormRow label="1st option">
            <input
              v-model="bet_option_a"
              :class="
                'w-full ' + (isLimited ? 'bg-gray-200 cursor-not-allowed' : '')
              "
              type="text"
              :disabled="isLimited"
            />
          </FormRow>
          <FormRow label="2nd option">
            <input
              v-model="bet_option_b"
              :class="
                'w-full ' + (isLimited ? 'bg-gray-200 cursor-not-allowed' : '')
              "
              type="text"
              :disabled="isLimited"
            />
          </FormRow>
        </UiPanel>

        <UiPanel label="Edit bet" class="mt-4">
          <LockIcon />
          <LockCheckIcon />
          <FlagCheckeredIcon />
        </UiPanel>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import GuildsDetailHeader from "../components/Guilds/GuildsDetailHeader.vue";

import LockCheckIcon from "vue-material-design-icons/LockCheck.vue";
import LockIcon from "vue-material-design-icons/Lock.vue";
import FlagCheckeredIcon from "vue-material-design-icons/FlagCheckered.vue";

import FormRow from "../components/Ui/Form/FormRow.vue";

import { mapState, mapGetters } from "vuex";
import UiButton from "../components/Ui/UiButton.vue";
import UiPanel from "../components/Ui/UiPanel.vue";
import UiAlert from "../components/Ui/UiAlert.vue";

export default {
  name: "BetDetail",
  data: () => ({
    loaded: false,
    finalOption: "",
    bet_subject: null,
    bet_option_a: null,
    bet_option_b: null,
    bet_url: null,
    bet_description: null,
  }),
  computed: {
    ...mapState("bet", {
      bet: (state) => state.bet,
    }),
    ...mapGetters("guild", ["isModerator"]),
    ...mapState("user", {
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    ...mapState("guild", {
      guild: (state) => state.guild,
    }),
    optionStyles: function () {
      return (option) => {
        let styles = [];
        if (this.selectedOption == option) {
          styles.push("bg-gray-200");
        }
        return styles.join(" ");
      };
    },
    selectedOption: function () {
      return this.bet?.myEntry?.option;
    },
    entries: function () {
      return (option) => {
        return this.bet.entries.filter((e) => e.option == option);
      };
    },
    odds: function () {
      return this.entries("a").length + ":" + this.entries("b").length;
    },
    finalOptionText: function () {
      let out = null;
      if (this.bet.finalOption) {
        out = this.bet[`option${this.bet.finalOption.toUpperCase()}`];
      }
      return out;
    },
    isLimited: function () {
      return this.bet?.entries?.length > 0;
    },
  },
  methods: {
    load: function () {
      this.$store.dispatch("bet/load", this.$route.params.id).then((bet) => {
        this.bet_subject = bet.subject;
        this.bet_option_a = bet.optionA;
        this.bet_option_b = bet.optionB;
        this.bet_url = bet.url;
        this.bet_description = bet.description;
      });
    },
    select: function (option) {
      if (!this.isLoggedIn) {
        this.$store.dispatch("user/openLoginModal");
      } else {
        if (!this.selectedOption) this.$store.dispatch("bet/entry", option);
      }
    },
    formatDate: function (date) {
      let m = moment(date);
      return m.format("YYYY-MM-DD HH:mm");
    },
    finish: function () {
      if (this.finalOption) {
        this.$store.dispatch("bet/finish", this.finalOption);
        this.mixpanel.track("bet_finish", {
          guildId: this.guild.id,
          betId: this.bet.id,
        });
      }
    },
    unfinish: function () {
      this.$store.dispatch("bet/unfinish");
      this.mixpanel.track("bet_unfinish", {
        guildId: this.guild.id,
        betId: this.bet.id,
      });
    },
  },
  mounted: function () {
    //this.bet.id = this.$route.params.id;
    this.load();
  },
  components: {
    GuildsDetailHeader,
    LockCheckIcon,
    LockIcon,
    FlagCheckeredIcon,
    FormRow,
    UiButton,
    UiPanel,
    UiAlert,
  },
};
</script>
