import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import store from './store'
import './assets/tailwind.css'

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);
import AmplifyVue from '@aws-amplify/ui-vue';

import api from './plugins/api'

// analytics https://github.com/Loschcode/vue-mixpanel
import VueMixpanel from '@/lib/mixpanel'
import VueGtag from "vue-gtag";

// components
import UiButton from "./components/Ui/UiButton";

import { plugin, defaultConfig } from '@formkit/vue'
import '@formkit/themes/dist/genesis/theme.css'

createApp(App)
                .use(store)
                .use(router)
                .use(AmplifyVue)
                .use(api)
                .use(plugin, defaultConfig)
                .use(VueGtag, {
                  config: { 
                    id: "G-9FD7Y3J07T",
                  },
                }, router)
                .use(VueMixpanel, {
                    token: '217b63a37637a1a61af3023fe70c12fe',
                    config: {
                      debug: true
                    },
                    store
                  })
                .component('UiButton', UiButton)
                .mount('#app')
