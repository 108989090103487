import { get } from '@/lib/apiConnector'

export default {
    namespaced: true,
    state: {
      standings: []
    },
    mutations: {
      setStandings(state, payload) {
          state.standings = payload;
      }
    },
    getters: {
      sortedStandings: function(state) {
        return state.standings
    }
    },
    actions: {
        load: function(context) {
            let guildId = context.rootState.guild.guild.id;
           
            if (guildId) {
                get(`/guilds/${guildId}/users/top`).then(response => {
                    context.commit('setStandings', response.data)
                })
            }
            
        }
    }
  }
  