import { Auth } from 'aws-amplify';
import { get } from '@/lib/apiConnector'

export default {
    namespaced: true,
    state: {
      user: {},
      cognitoUser: {},
      isLoggedIn: false,
      authModal: false
    },
    mutations: {
      setUser(state, payload) {
          state.user = payload;
      },
      setCognitoUser(state, payload) {
        state.cognitoUser = payload;
    },
      setIsLoggedIn(state, payload) {
          state.isLoggedIn = payload;
      },
      setAuthModal(state, payload) {
        state.authModal = payload;
    },
    },
    getters: {
      
    },
    actions: {
        openLoginModal: function(context) {
            context.commit('setAuthModal', true)
        },
        load: function(context) {
            get('/users/me').then(result => {
                context.commit('setUser', result.data)
            })
        },
        check: function(context) {
            Auth.currentSession()
                .then(user => {
                    context.commit('setCognitoUser', user)
                    context.commit('setIsLoggedIn', true)
                    context.commit('setAuthModal', false)
                    get('/users/me').then(result => {
                        context.commit('setUser', result.data)
                        this.mixpanel.identify(result.data.id);
                    })
                    
                    
                })
                .catch((err) => {
                    console.log(err);
                    context.commit('setIsLoggedIn', false)
                    context.commit('setUser', {})
                })
        }
    }
  }
  