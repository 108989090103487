<template>
  <div class="home pt-2 pr-2">
    <div class="grid grid-cols-2 xl:grid-cols-3 gap-2">
      <GuildsListItem
        v-for="guild in guilds"
        :key="guild.id"
        :guild="guild"
        @click="$router.push(`/guild/${guild.id}`)"
        class="cursor-pointer"
      />
      <div
        v-if="!archived"
        class="border rounded-md p-2 relative mb-2 cursor-pointer hover:bg-gray-100"
        @click="$router.push('/create-guild')"
      >
        <div class="text-center text-xl p-4 text-gray-400">
          + Create new group
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import GuildsListItem from "@/components/Guilds/GuildsListItem";

export default {
  name: "GuildsList",
  props: {
    archived: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    guilds: [],
  }),
  components: {
    GuildsListItem,
  },
  methods: {
    load: function () {
      let path = "/guilds";
      if (this.archived) path += "?scope=archived";

      this.$api.get(path).then((result) => (this.guilds = result.data));
    },
  },
  mounted: function () {
    this.load();
  },
};
</script>
