<template>
    <div class="flex items-center border-t border-b relative bg-gray-300" @mouseenter="hover=true" @mouseleave="hover=false">
        <div class="bg-white rounded-full p-2 w-16 bg-gray-300">
            <img src="@/assets/avatar.jpg" class="border rounded-full">
        </div>
        <div v-if="user" class="block">
            <div class="font-bold">{{ user.display_name || 'Anonymous' }}</div>
            <div class="grid grid-cols-3 gap-2 text-xs ">
                <div><TrophyIcon :size="11" class="inline-block" /> {{ won }}</div>
                <div><TargetIcon :size="11" class="inline-block" /> {{ percentage }}%</div>
            </div>
        </div>
        <div v-if="isLoggedIn && hover" class="absolute top-0 right-0 bottom-0 bg-red-400 cursor-pointer p-2 flex items-center text-center text-white" @click="signout()">
            <LogoutVariantIcon />
        </div>

    </div>
</template>

<script>
  import { Auth } from 'aws-amplify';

  import TargetIcon from 'vue-material-design-icons/Target.vue';
  import TrophyIcon from 'vue-material-design-icons/Trophy.vue';
  import LogoutVariantIcon from 'vue-material-design-icons/LogoutVariant.vue';
  
  import { formatCredit } from '@/lib/formatUtil';

import { mapState } from 'vuex';
export default {
    name: 'UserPanel',
    components: {
        TargetIcon,
        TrophyIcon,
        LogoutVariantIcon
    },
    data: () => ({
        hover: false,
    }),
    computed: {
        ...mapState('user', {
            user: state => state.user,
            isLoggedIn: state => state.isLoggedIn
        }),
        won: function() {
            return this.user?.won || 0
        },
        rate: function() {
            let won = (this.user?.won || 0) + 1;
            let lost = (this.user?.lost || 0) + 1;
            let rate = won / lost;
            
            return Math.round(rate*100)/100
            
        },
        percentage: function() {
            let total = (this.user?.won || 0) + (this.user?.lost || 0) ;

            if (total>0) {
                return Math.round(100/total*(this.user?.won || 0));
            }
            else {
                return 0;
            }
           
        },
        credit: function() {
            if (typeof this.user.credit !== 'undefined')
                return this.user.credit;
            else
                return 0;
        }
    },
    methods: {
        signout: function() {
            Auth.signOut();
        },
        formatCredit: formatCredit
    },
}
</script>