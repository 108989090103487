<template>
  <div>
    <FormKit type="form" @submit="handleSubmit">
      <FormKit
        type="file"
        name="image"
        label="Cover image"
        accept=".jpg,.jpeg,.png,.gif"
      />
      <FormKit
        type="file"
        name="background"
        label="Background image"
        accept=".jpg,.jpeg,.png,.gif"
      />
    </FormKit>
  </div>
</template>

<script>
//import { post } from "@/lib/apiConnector";

export default {
  name: "GuildsEditImage",
  data: () => ({
    progress: 0,
    uploading: false,
    imageSrc: null,
  }),
  methods: {
    handleUpload: async function (file, type) {
      let matches = file.name.match(/\.([a-zA-Z0-9]+)$/);
      let extension = matches ? matches[1] : "txt";
      let mime = file.type || "application/octet-stream";

      let s3Url = await this.$store.dispatch("guild/getUploadUrl", {
        type,
        extension,
        mime: file.type || "application/octet-stream",
      });

      if (s3Url.url) {
        console.log(s3Url);

        const xhr = new XMLHttpRequest();
        xhr.open("PUT", s3Url.url);
        xhr.setRequestHeader("Content-Type", mime);

        try {
          await new Promise((resolve, reject) => {
            xhr.onload = () =>
              xhr.status - 200 < 100
                ? resolve()
                : reject(new Error("Failed to upload"));
            xhr.onerror = () => reject(new Error("Failed to upload"));
            xhr.send(file);
          });

          return `/${s3Url.key}`;
        } catch {
          // we'll suppress this since we have a catch all error
        }
      } else {
        return null;
      }
    },
    handleSubmit: async function (values) {
      let updateData = {};
      if (values.image.length == 1) {
        let file = values.image[0].file;
        updateData.coverImage = await this.handleUpload(file, "cover");
      }
      if (values.background.length == 1) {
        let file = values.background[0].file;
        updateData.backgroundImage = await this.handleUpload(
          file,
          "background"
        );
      }
      if (Object.keys(updateData).length > 0) {
        this.$store.dispatch("guild/update", updateData);
      }
    },
  },
};
</script>
