<template>
  <div class="home p-4">
    <div v-if="isLoggedIn">
      <h1>Create bet wizzard</h1>

      <p>Select group where you want to create the bet</p>
      <select v-model="guild">
        <option
          v-for="option in guilds"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div v-else>Not logged in</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BetCreateTampermonkey",
  data: () => ({
    guild: null,
  }),

  computed: {
    ...mapState("user", {
      user: (state) => state.user,
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    guilds: function () {
      let out = [];
      if (this.user && this.user.guild_users) {
        out = this.user.guild_users.map((gu) => ({
          label: gu.guild.name,
          value: gu.guild.id,
        }));
      }
      return out;
    },
  },
  methods: {
    findGuildByName: function () {
      let name = null;
      if (this.$route.query?.guild) {
        name = this.$route.query.guild;
        if (this.guilds.length > 0) {
          let g = this.guilds.find((g) => g.label == name);
          if (g) {
            this.guild = g.value;
          }
        }
      }
    },
  },
  watch: {
    user: function () {
      this.findGuildByName();
    },
    guild: function (newVal) {
      this.$router.push({
        name: "BetCreate",
        params: { id: newVal },
        query: this.$route.query,
      });
    },
  },
};
</script>
