import { get, post } from '@/lib/apiConnector'

export default {
    namespaced: true,
    state: {
      achievements: []
    },
    mutations: {
      setAchievements(state, payload) {
          state.achievements = payload;
      }
    },
    getters: {
      sortedAchievements: function(state) {
        return state.achievements
    }
    },
    actions: {
        load: function(context) {
            let guildId = context.rootState.guild.guild.id;
           
            if (guildId) {
                get(`/guilds/${guildId}/achievements`).then(response => {
                    context.commit('setAchievements', response.data)
                })
            }
        },
        generate: function(context) {
          let guildId = context.rootState.guild.guild.id;
         
          if (guildId) {
              post(`/guilds/${guildId}/achievements`).then(() => context.dispatch('load'))
          }
      }
    }
  }
  