<template>
  <div class="">
    <div
      v-for="(item, index) in menuItems"
      :key="index"
      class="border-b py-2 bg-gray-200 pl-4 pr-4 cursor-pointer hover:bg-gray-300 flex items-center justify-between text-gray-500 hover:text-black"
      @click="onClick(item.to)"
    >
      <div class="flex">
        <component
          v-if="item.icon"
          :is="item.icon"
          :class="`inline-block mr-2 ${item.iconClass}`"
        ></component>
        {{ item.name }}
      </div>
      <div
        v-if="item.badgeValue"
        class="rounded px-2 text-sm bg-green-400 text-white font-bold"
      >
        {{ item.badgeValue }}
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef } from "vue";
// eslint-disable-next-line no-unused-vars
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import ArchiveIcon from "vue-material-design-icons/Archive.vue";
//import CashMultipleIcon from "vue-material-design-icons/CashMultiple.vue";
import StarIcon from "vue-material-design-icons/Star.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import DiscordIcon from "vue-material-design-icons/Discord.vue";

import { mapState } from "vuex";

export default {
  name: "MenuPanel",
  data: () => ({
    items: [{ name: "Explore groups", icon: shallowRef(MagnifyIcon), to: "/" }],
    itemsAppend: [
      {
        name: "Create new group!",
        icon: shallowRef(PlusIcon),
        to: "/create-guild",
        iconClass: "text-green-500",
      },
      { name: "Archive", icon: shallowRef(ArchiveIcon), to: "/archive" },
      {
        name: "Join our Discord",
        icon: shallowRef(DiscordIcon),
        to: "https://discord.gg/rrSyD23RHG",
      },
    ],
  }),
  computed: {
    ...mapState("user", {
      user: (state) => state.user,
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    menuItems: function () {
      let guildItems = [];
      if (
        this.user &&
        this.user.guild_users &&
        this.user.guild_users.length > 0
      ) {
        guildItems = this.user.guild_users.map((gu) => {
          let icon = StarIcon;

          return {
            name: gu.guild.name,
            icon: icon,
            iconClass: "text-yellow-500",
            badgeValue: gu.waitingBets,
            to: { name: "GuildDetail", params: { id: gu.guild.id } },
          };
        });
        // asd
      }
      return [...this.items, ...guildItems, ...this.itemsAppend];
    },
  },
  methods: {
    onClick: function (to) {
      if (typeof to === "string" && to.substr(0, 4) == "http") {
        window.open(to, "_blank").focus();
      } else {
        this.$router.push(to);
      }
    },
  },
};
</script>
