<template>
  <div>
    <UiButton
      block
      color="red-600"
      class="text-white font-bold"
      @click="generate"
    >
      Generate achievements
    </UiButton>
  </div>
</template>

<script>
// @ is an alias to /src
import UiButton from "@/components/Ui/UiButton.vue";

export default {
  name: "GuildsGenerateAchievements",

  data: () => ({
    archiveConfirm: null,
  }),
  computed: {
    enableArchive: function () {
      return this.archiveConfirm === "ARCHIVE";
    },
  },
  components: {
    UiButton,
  },
  methods: {
    generate: function () {
      this.$store.dispatch("guild/achievements/generate", null, { root: true });
    },
  },
};
</script>
