<template>
  <div
    :class="
      'flex items-end justify-between w-full bg-gray-200 p-4 bg-cover bg-center ' +
      (coverImage ? ' h-60' : ' h-48')
    "
    :style="guildStyle"
  >
    <div class="grow">
      <h1 class="font-bold text-4xl mb-4">{{ guild.name }}</h1>
      <div>
        <CalendarIcon class="inline-block" :size="18" /> Created
        {{ guildSince }}
      </div>
    </div>
    <div class="flex-none">
      <div v-if="joined" class="flex relative h-12 align-center">
        <UiButton
          color="gray-300 border-gray-400 mr-2"
          @click="updateNotify(!notify)"
        >
          <BellCancelIcon v-if="notify"></BellCancelIcon>
          <BellIcon v-else></BellIcon>
        </UiButton>
        <UiButton
          v-if="hasGuildRole(guild.id, 'moderator')"
          block
          class="mr-2"
          @click="$router.push(`/guild/${guild.id}/new`)"
          color="green-400 border-green-500"
        >
          Create new bet
        </UiButton>

        <UiButton color="gray-300 border-gray-400" @click="submenu = !submenu">
          <DotsVerticalIcon />
        </UiButton>
        <div
          v-if="submenu"
          class="absolute top-0 mt-12 right-0 w-32 bg-gray-300 p-1 rounded"
        >
          <UiButton
            v-if="hasGuildRole(guild.id, 'admin')"
            block
            class=""
            @click="$router.push(`/guild/${guild.id}/edit`)"
            color="blue-400"
          >
            Edit
          </UiButton>
          <UiButton
            v-if="!hasGuildRole(guild.id, 'owner')"
            block
            color="red-400"
            @click="leave"
            >Leave group</UiButton
          >
        </div>
      </div>
      <div v-else-if="canJoin">
        <UiButton color="green-400" @click="join">Join group</UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { mapState, mapGetters } from "vuex";

import UiButton from "@/components/Ui/UiButton.vue";
import CalendarIcon from "vue-material-design-icons/Calendar.vue";
import BellIcon from "vue-material-design-icons/Bell.vue";
import BellCancelIcon from "vue-material-design-icons/BellCancel.vue";
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical.vue";

export default {
  name: "GuildsDetailHeader",
  components: {
    UiButton,
    CalendarIcon,
    BellIcon,
    BellCancelIcon,
    DotsVerticalIcon,
  },
  props: {
    guild: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    submenu: false,
  }),
  computed: {
    ...mapState("user", {
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user,
    }),
    ...mapGetters("guild", ["isMember", "hasGuildRole"]),
    guildSince: function () {
      let m = moment(this.guild.createdAt);
      return m.format("MMMM YYYY");
    },
    joined: function () {
      return (
        this.isLoggedIn &&
        this.guild.guild_users?.find((gu) => gu.userId == this.user.id)
      );
    },
    canJoin: function () {
      return this.guild.privacy == "public" || this.guild.privacy == "unlisted";
    },
    notify: function () {
      if (this.joined) {
        let gu = this.guild.guild_users?.find(
          (gu) => gu.userId == this.user.id
        );
        return gu.notify;
      }
      return null;
    },
    coverImage: function () {
      return this.guild && this.guild.coverImage
        ? `https://treefiddy.club${this.guild.coverImage}`
        : null;
    },
    guildStyle: function () {
      let style = {};
      if (this.coverImage) {
        style.backgroundImage = `url(${this.coverImage})`;
      }

      return style;
    },
  },
  methods: {
    join: function () {
      if (this.isLoggedIn) {
        // pripojit ke skupine
        this.$store.dispatch("guild/follow", this.guild.id);
      } else {
        this.$store.dispatch("user/openLoginModal");
      }
    },
    leave: function () {
      this.$store.dispatch("guild/unfollow", this.guild.id);
    },
    updateNotify: function (newVal) {
      if (this.isLoggedIn) {
        // pripojit ke skupine
        this.$store.dispatch("guild/updateNotify", newVal);
      } else {
        this.$store.dispatch("user/openLoginModal");
      }
    },
  },
};
</script>
