<template>
  <div class="home p-4">
    <div class="mb-2">
      <label class="block font-bold">Group name</label>
      <input type="text" v-model="name" placeholder="Group name" />
    </div>
    <div class="mb-2">
      <label class="block font-bold">Privacy</label>
      <select v-model="privacy">
        <option
          v-for="option in privacyOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
    </div>
    <!--
    <div class="mb-2">
      <label class="block font-bold">Who can create bets</label>
      <select v-model="createBetLevel">
        <option v-for="option in createBetLevelOptions" :key="option.value" :value="option.value">{{ option.name }}</option>
      </select>
    </div>
    -->
    <div class="mt-4">
      <UiButton @click="save()" color="green-400">Create guild</UiButton>
    </div>
  </div>
</template>

<script>
import UiButton from "../components/Ui/UiButton.vue";
// @ is an alias to /src

export default {
  name: "GuildCreate",
  data: () => ({
    name: null,
    privacy: "public",
    privacyOptions: [
      { value: "public", name: "Public" },
      { value: "unlisted", name: "Unlisted (only people with link)" },
      { value: "private", name: "Private (invite only)" },
    ],
    createBetLevel: "user",
    /*
        createBetLevelOptions: [
          {value: 'user', name: 'Anyone in the guild'},
          {value: 'moderator', name: 'Only guild moderators'}
        ]
        */
  }),
  methods: {
    save: function () {
      console.log(this.$api, this);
      this.$api
        .post("/guilds", {
          name: this.name,
          privacy: this.privacy,
          create_bet_level: "moderator",
        })
        .then((response) => {
          this.$router.push(`/guild/${response.data.id}`);
          this.mixpanel.track("guild_create", {
            guildId: response.data.id,
            name: this.name,
            privacy: this.privacy,
          });
        });
    },
  },
  components: { UiButton },
};
</script>
