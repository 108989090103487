<template>
  <div id="megaholder" class="bg-fixed bg-cover" :style="style">
    <div
      class="container mx-auto max-w-screen-xl md:border-r border-b bg-white"
    >
      <div
        class="md:flex flex-col md:flex-row md:min-h-screen w-full drop-shadow-2xl"
      >
        <div
          class="flex flex-col w-full md:w-1/3 xl:w-1/4 flex-shrink-0 md:border-l md:border-r-4 border-gray-300 bg-gray-100"
        >
          <div class="flex justify-center items-center bg-black text-white">
            <LogoPanel class="ml-4 md:ml-0" />
            <div
              :class="
                'md:hidden mr-4 transition-all transform cursor-pointer' +
                (menu ? ' rotate-180 animate-pulse' : '')
              "
              @click="menu = !menu"
            >
              <MenuIcon />
            </div>
          </div>
          <div :class="menuClass">
            <UserPanel v-if="isLoggedIn" />
            <div
              v-else
              @click="$store.commit('user/setAuthModal', true)"
              class="text-center p-4 border rounded bg-green-400 cursor-pointer hover:bg-green-500"
            >
              Sign in / Create account
            </div>

            <MenuPanel />
          </div>
        </div>
        <div class="flex-auto border-gray-300 border-b-4">
          <router-view />
        </div>
      </div>
      <div
        v-if="authModal"
        class="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center"
        style="background-color: #00000070"
        @click="$store.commit('user/setAuthModal', modalMouseOver)"
      >
        <div
          class="w-full md:w-1/2 min-h-1/2 p-8 border rounded bg-white relative flex justify-center"
          @mouseenter="modalMouseOver = true"
          @mouseleave="modalMouseOver = false"
        >
          <authenticator :login-mechanisms="['username']"> auth </authenticator>
        </div>
      </div>
      <div
        class="bg-gray-100 border-l border-gray-300 text-xs p-1 px-4 text-gray-400 text-right"
      >
        Created by
        <a
          href="https://twitter.com/tusil"
          target="_blank"
          rel="noopener noreferrer"
          >tusil</a
        >
      </div>
    </div>
    <AddThis publicId="ra-62338e4ab7dcd582" />
  </div>
</template>

<script>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import UserPanel from "@/components/App/UserPanel";
import LogoPanel from "./components/App/LogoPanel.vue";
import MenuPanel from "./components/App/MenuPanel.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import { mapState } from "vuex";
import AddThis from "vue-simple-addthis-share/src/AddThis.vue";

const GeoPattern = require("geopattern");

export default {
  name: "App",
  inject: ["mixpanel"],
  components: {
    Authenticator,
    UserPanel,
    LogoPanel,
    MenuPanel,
    MenuIcon,
    AddThis,
  },
  data: () => ({
    modalMouseOver: false,
    menu: false,
  }),
  computed: {
    ...mapState("user", {
      authModal: (state) => state.authModal,
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    ...mapState("guild", {
      guild: (state) => state.guild,
    }),
    menuClass: function () {
      let cls = ["bg-gray-50", "md:block"];
      if (!this.menu) cls.push("hidden");
      return cls.join(" ");
    },
    backgroundImage: function () {
      let color = "#d3d3d3";
      //let color = "#4a5871";
      let seed = "Tree Fiddy Club";

      if (this.guild && this.guild.coverImage) {
        // set
      }

      var pattern = GeoPattern.generate(seed, {
        color,
      });

      return pattern.toDataUrl(); // url("data:image/svg+xml;...
    },
    style: function () {
      let style = {
        backgroundImage: `${this.backgroundImage}`,
      };

      return style;
    },
  },
  watch: {
    $route: function (newVal) {
      this.mixpanel.track("pageview", {
        path: newVal.path,
      });
    },
  },
  mounted() {
    this.mixpanel.track("pageview", {
      path: this.$route.path,
    });
  },
};
</script>
