<template>
  <div class="home p-4">
    <h1>Group archive</h1>
    <GuildsList :archived="true" />
  </div>
</template>

<script>
// @ is an alias to /src

import GuildsList from "../components/Guilds/GuildsList.vue";

export default {
  name: "GuildArchive",
  data: () => ({
    bets: [],
    guilds: [],
  }),
  components: {
    GuildsList,
  },
};
</script>
