<template>
  <div class="w-full flex items-center py-4 justify-evenly">
    <div class="bg-white rounded-full p-2 w-16 relative">
      <router-link to="/"
        ><img src="@/assets/logo.png" :class="logoClass"
      /></router-link>
      <div
        v-if="isLoading"
        class="absolute top-6 left-0 right-0 text-xs text-center rotate-45"
      >
        loading ...
      </div>
    </div>
    <div class="">
      <div class="font-bold text-2xl">tree fiddy club</div>
      <div class="text-xs">Don't lose money, bet for fame ❤️</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LogoPanel",
  components: {},
  computed: {
    ...mapState("loader", {
      active: (state) => state.active,
      total: (state) => state.total,
    }),
    isLoading: function () {
      return this.active != this.total;
    },
    logoClass: function () {
      return this.isLoading ? "animate-spin" : "";
    },
  },
  mounted: function () {},
};
</script>
