<template>
  <div class="home">
    <GuildsDetailHeader v-if="guild" :guild="guild" />

    <div v-if="guild" class="flex p-4 pb-0 border-b-2">
      <div class="grow px-8 py-2 bg-gray-100">
        <router-link :to="`/guild/${guild.id}`">&lt; back to group</router-link>
      </div>
      <div
        class="grow border-b-4 rounded-t border-green-400 px-8 py-2 bg-gray-100 ml-4"
      >
        Create new bet
      </div>
    </div>

    <div v-if="guild && hasGuildRole(guild.id, 'moderator')">
      <UiPanel
        :label="`Create new bet in ${guild.name}`"
        :flex="false"
        class="mt-4 flex-row"
      >
        <div class="text-sm border-b mb-2 pl-4 text-gray-400">
          Basic configuration
        </div>

        <FormRow label="Bet subject">
          <input type="text" v-model="subject" placeholder="" class="w-full" />
        </FormRow>

        <div class="flex">
          <FormRow label="1st option" class="w-1/2 pr-2">
            <input
              type="text"
              v-model="optionA"
              placeholder=""
              class="w-full"
            />
          </FormRow>
          <FormRow label="2nd option" class="w-1/2 pl-2">
            <input
              type="text"
              v-model="optionB"
              placeholder=""
              class="w-full"
            />
          </FormRow>
        </div>

        <div class="text-sm border-b mb-2 mt-4 pl-4 text-gray-400">
          Extended info
        </div>
        <FormRow label="URL">
          <input
            type="text"
            v-model="url"
            placeholder="https://www.example.com"
            class="w-full"
            @paste="onPaste"
          />
          <div v-if="parsingUrl" class="p-2 animate-pulse text-sm">
            Parsing url ...
          </div>
        </FormRow>
        <FormRow label="Description">
          <textarea v-model="description" class="w-full"> </textarea>
        </FormRow>

        <div class="text-sm border-b mb-2 mt-4 pl-4 text-gray-400">Dates</div>

        <div class="flex">
          <FormRow label="Close bet for new entries" class="w-1/2 pr-2">
            <input type="datetime-local" v-model="closeAt" class="w-full" />
            <div class="text-sm flex mt-2">
              <div
                v-for="interval in intervals"
                :key="interval.label"
                class="border rounded px-1 mr-1 hover:bg-green-100 cursor-pointer"
                @click="prefill('closeAt', interval.n, interval.s)"
              >
                {{ interval.label }}
              </div>
            </div>
          </FormRow>
          <FormRow label="Expected resolution date" class="w-1/2 pl-2">
            <input type="datetime-local" v-model="finishAt" class="w-full" />
            <div class="text-sm flex mt-2">
              <div
                v-for="interval in intervals"
                :key="interval.label"
                class="border rounded px-1 mr-1 hover:bg-green-100 cursor-pointer"
                @click="prefill('finishAt', interval.n, interval.s)"
              >
                {{ interval.label }}
              </div>
            </div>
          </FormRow>
        </div>
        <div class="text-center mt-4">
          <UiButton block @click="save()" color="green-400">Create</UiButton>
        </div>
      </UiPanel>
    </div>
    <div v-else>You can't add bet :(</div>
  </div>
</template>

<script>
import FormRow from "../components/Ui/Form/FormRow.vue";
import UiButton from "../components/Ui/UiButton.vue";
import GuildsDetailHeader from "../components/Guilds/GuildsDetailHeader.vue";

import moment from "moment";
import { mapState, mapGetters } from "vuex";
import UiPanel from "../components/Ui/UiPanel.vue";

import { post } from "@/lib/apiConnector";
// @ is an alias to /src

export default {
  name: "Home",
  components: { FormRow, UiButton, GuildsDetailHeader, UiPanel },
  data: () => ({
    subject: null,
    guildId: null,
    optionA: "Yes",
    optionB: "No",
    url: null,
    description: null,
    closeAt: null,
    finishAt: null,

    parsingUrl: false,

    guilds: [],
    intervals: [
      { label: "1h", n: 1, s: "h" },
      { label: "12h", n: 12, s: "h" },
      { label: "1d", n: 1, s: "d" },
      { label: "2d", n: 2, s: "d" },
      { label: "3d", n: 3, s: "d" },
      { label: "1w", n: 1, s: "w" },
      { label: "2w", n: 2, s: "w" },
      { label: "1m", n: 1, s: "m" },
      { label: "1y", n: 1, s: "y" },
    ],
  }),
  computed: {
    ...mapState("guild", {
      guild: (state) => state.guild,
    }),
    ...mapGetters("guild", ["hasGuildRole"]),
  },
  methods: {
    save: function () {
      this.$api
        .post("/bets", {
          subject: this.subject,
          guildId: this.$route.params.id,
          url: this.url,
          description: this.description,
          optionA: this.optionA,
          optionB: this.optionB,
          closeAt: moment(this.closeAt).valueOf(),
          finishAt: moment(this.finishAt).valueOf(),
        })
        .then((result) => {
          if (result.data?.id) {
            this.$router.push(`/bets/${result.data.id}`);
            this.mixpanel.track("bet_create", {
              guildId: this.$route.params.id,
              betId: result.data.id,
              subject: this.subject,
              optionA: this.optionA,
              optionB: this.optionB,
              closeAt: moment(this.closeAt).valueOf(),
              finishAt: moment(this.finishAt).valueOf(),
            });
          }
        });
    },
    load: function () {
      this.$store.dispatch("guild/load", this.$route.params.id);
    },
    prefill: function (obj, n, s) {
      this[obj] = moment().add(n, s).toISOString().substring(0, 16);
    },
    onPaste: function (e) {
      let pastedData = e.clipboardData.getData("text");

      if (
        pastedData.match(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i
        )
      ) {
        console.log(pastedData);
        this.parsingUrl = true;
        post("/parse-url", {
          url: pastedData,
        })
          .then((result) => {
            if (result.data) {
              if (result.data.subject) this.subject = result.data.subject;
              if (result.data.optionA) this.optionA = result.data.optionA;
              if (result.data.optionB) this.optionB = result.data.optionB;
              if (result.data.closeAt)
                this.closeAt = moment(result.data.closeAt).format(
                  "YYYY-MM-DD[T]HH:mm"
                );
              if (result.data.finishAt)
                this.finishAt = moment(result.data.finishAt).format(
                  "YYYY-MM-DD[T]HH:mm"
                );
            }
            this.parsingUrl = false;
          })
          .catch((err) => {
            this.parsingUrl = false;
            console.error(err);
          });
      }
    },
  },
  watch: {
    $route: function (newVal) {
      if (newVal.name == "BetCreate")
        this.$store.dispatch("guild/load", this.$route.params.id);
    },
  },
  mounted: function () {
    this.load();
    this.closeAt = new Date(new Date().getTime() + 2 * 3600 * 1000)
      .toISOString()
      .substring(0, 16);
    this.finishAt = new Date(new Date().getTime() + 26 * 3600 * 1000)
      .toISOString()
      .substring(0, 16);

    if (this.$route.query?.subject) this.subject = this.$route.query.subject;
    if (this.$route.query?.optionA) this.optionA = this.$route.query.optionA;
    if (this.$route.query?.optionB) this.optionB = this.$route.query.optionB;
    if (this.$route.query?.url) this.url = this.$route.query.url;
    if (this.$route.query?.closeAt) {
      this.closeAt = moment(parseInt(this.$route.query.closeAt)).format(
        "YYYY-MM-DD[T]HH:mm"
      );
    }
    if (this.$route.query?.finishAt) {
      this.finishAt = moment(parseInt(this.$route.query.finishAt)).format(
        "YYYY-MM-DD[T]HH:mm"
      );
    }
  },
};
</script>
