import mixpanel from 'mixpanel-browser'

export default {
  install: (app, params) => {
    const defaultConfig = {}
    const endConfig = Object.assign(params.config, defaultConfig)

    mixpanel.init(params.token, endConfig)
    app.provide('mixpanel', mixpanel)

    // inject mixpanel to store
    if (params.store)
        params.store.mixpanel = mixpanel;
  }
}