import { createStore } from 'vuex'

import { Hub } from 'aws-amplify';

import version from '@/version.js'

function createHubPlugin(hub) {
    return (store) => {
        hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signIn':
                case 'signUp':
                    store.dispatch('user/check', data);
                break;
                case 'signOut':
                    store.dispatch('user/check', data);
                break;
                
                case 'signIn_failure':
                    console.log('user sign in failed');
                    break;
                case 'configured':
                    store.dispatch('user/check', data);
                
              }
        })
    }
}

var hubPlugin = createHubPlugin(Hub);

import loader from './loader'
import user from './user'
import bet from './bet'
import guild from './guild'


export default createStore({
  namespaced: true,
  plugins: [hubPlugin],
  state: {
    version: parseInt(version),
    apiVersion: null
  },
  getters: {
    needUpdate: function(state) {
        if (state.version && state.apiVersion) {
            return state.apiVersion > state.version
        }
        return false;
    }
  },
  modules: {
    // layout
    loader,
    user,
    bet,
    guild
  }
})
