<template>
  <div :class="style">
    <span class="font-bold">{{ alertName }}</span> <slot></slot>
  </div>
</template>

<script>
export default {
  name: "UiAlert",
  props: {
    type: {
      type: String,
      default: "warning",
    },
    name: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    alertName: function () {
      if (this.name) return this.name;
      switch (this.type) {
        case "warning":
          return "Warning!";
      }
      return null;
    },
    style: function () {
      let classes = ["border", "p-4", "mb-2"];
      if (this.type == "warning") {
        classes.push(`border-yellow-600 bg-yellow-200`);
      }

      return classes.join(" ");
    },
  },
};
</script>
