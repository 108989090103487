import { get, patch, del } from '@/lib/apiConnector'

export default {
    namespaced: true,
    state: {
      users: []      
    },
    mutations: {
      setUsers(state, payload) {
          state.users = payload;
      },
      updateUser(state, payload) {
        let user = state.users.find(u => u.id == payload.id);
        Object.assign(user, payload);
      },
      deleteUser(state, payload) {
        let userIndex = state.users.findIndex(u => u.id == payload.id);
        state.users.splice(userIndex, 1);
      }
    },
    getters: {
      sortedUsers: function(state) {
        return state.users
      }
    },
    actions: {
        load: function(context, guildId) {
            if (guildId) {
                get(`/guilds/${guildId}/users`).then(response => {
                    context.commit('setUsers', response.data)
                })
            }
        },
        update: function(context, payload) {
          let guildId = context.rootState.guild.guild.id;
          patch(`/guilds/${guildId}/users/${payload.userId}`, {
              level: payload.level
            }).then(response => {
              context.commit('updateUser', response.data)
          })
        },
        del: function(context, payload) {
          let guildId = context.rootState.guild.guild.id;
          del(`/guilds/${guildId}/users/${payload.userId}`).then(() => {
              context.commit('deleteUser', payload.userId)
          })
        }
    }
  }
  