<template>
  <div class="home pb-4">
    <GuildsDetailHeader v-if="guild" :guild="guild" />

    <div v-if="bet">
      <MenuTop :items="menuItems" />
      <div class="mx-4">
        <h1 class="font-bold text-xl m-12 mb-4 text-center">
          <a
            v-if="bet.url"
            :href="bet.url"
            rel="noopener noreferrer"
            target="_blank"
            class="underline"
            >{{ bet.subject }} <OpenInNewIcon :size="16" class="inline-block"
          /></a>
          <span v-else>{{ bet.subject }}</span>
        </h1>

        <div v-if="bet.description" class="w-3/4 m-auto mb-4 text-gray-600">
          {{ bet.description }}
        </div>
        <div class="text-center text-sm mb-8">
          <LockIcon class="inline-block" :size="12" /> lock entries:
          {{ formatDate(bet.closeAt) }}<br />
          <FlagCheckeredIcon class="inline-block" :size="12" /> expected finish:
          {{ formatDate(bet.finishAt) }}
        </div>
        <div
          v-if="showMemberBet"
          class="bg-red-400 border rounded my-2 p-4 text-center"
        >
          You have to join group
          <span class="font-bold">{{ guild.name }}</span> to participate in this
          bet.
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="border rounded relative">
            <div class="p-4 mb-7">
              <UiButton
                block
                :class="optionStyles('a')"
                @click="select('a')"
                :disabled="!canEditEntry"
              >
                <span v-if="selectedOption == 'a'" class="text-sm">
                  <CashMultipleIcon
                    :size="12"
                    class="inline-block"
                  ></CashMultipleIcon>
                  3.50 on
                </span>
                <span class="font-bold">{{ bet.optionA }}</span>
              </UiButton>
              <div class="mt-2">
                <ul v-if="entries('a').length > 0">
                  <li
                    v-for="(entry, index) in entries('a')"
                    :key="`entries-a-${index}`"
                    class="text-center text-xs"
                  >
                    {{ entry.user.display_name || "Anonymous" }}
                  </li>
                </ul>
                <div v-else class="text-center text-xs text-gray-100">
                  - nobody -
                </div>
              </div>
            </div>
            <div
              class="grid grid-cols-2 text-xs text-center border-t pt-2 pb-2 absolute bottom-0 w-full"
            >
              <div title="total entries">
                <AccountGroupIcon
                  class="inline-block"
                  :size="12"
                ></AccountGroupIcon>
                {{ entries("a").length }}
              </div>
              <div title="possible win">
                <CashMultipleIcon
                  class="inline-block"
                  :size="12"
                ></CashMultipleIcon>
                {{ possibleWins[0] }}
              </div>
            </div>
          </div>

          <div class="border rounded relative">
            <div class="p-4 mb-7">
              <UiButton
                block
                :class="optionStyles('b')"
                @click="select('b')"
                :disabled="!canEditEntry"
              >
                <span v-if="selectedOption == 'b'" class="text-sm">
                  <CashMultipleIcon
                    :size="12"
                    class="inline-block"
                  ></CashMultipleIcon>
                  3.50 on
                </span>
                <span class="font-bold">{{ bet.optionB }}</span>
              </UiButton>
              <div class="mt-2">
                <ul v-if="entries('b').length > 0">
                  <li
                    v-for="(entry, index) in entries('b')"
                    :key="`entries-b-${index}`"
                    class="text-center text-xs"
                  >
                    {{ entry.user.display_name || "Anonymous" }}
                  </li>
                </ul>
                <div v-else class="text-center text-xs text-gray-100">
                  - nobody -
                </div>
              </div>
            </div>
            <div
              class="grid grid-cols-2 text-xs text-center border-t pt-2 pb-2 absolute bottom-0 w-full"
            >
              <div title="total entries">
                <AccountGroupIcon
                  class="inline-block"
                  :size="12"
                ></AccountGroupIcon>
                {{ entries("b").length }}
              </div>
              <div title="possible win">
                <CashMultipleIcon
                  class="inline-block"
                  :size="12"
                ></CashMultipleIcon>
                {{ possibleWins[1] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import GuildsDetailHeader from "../components/Guilds/GuildsDetailHeader.vue";

import LockIcon from "vue-material-design-icons/Lock.vue";
import FlagCheckeredIcon from "vue-material-design-icons/FlagCheckered.vue";
import OpenInNewIcon from "vue-material-design-icons/OpenInNew.vue";
import CashMultipleIcon from "vue-material-design-icons/CashMultiple.vue";
import AccountGroupIcon from "vue-material-design-icons/AccountGroup.vue";

import { mapState, mapGetters } from "vuex";

import { formatCredit } from "@/lib/formatUtil";
import MenuTop from "../components/App/MenuTop.vue";

export default {
  name: "BetDetail",
  inject: ["mixpanel"],
  data: () => ({
    loaded: false,
    showMemberBet: false,
  }),
  computed: {
    ...mapState("bet", {
      bet: (state) => state.bet,
    }),
    ...mapGetters("bet", ["canEdit"]),
    ...mapState("user", {
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    ...mapState("guild", {
      guild: (state) => state.guild,
    }),
    ...mapGetters("guild", ["isMember", "isModerator"]),
    menuItems: function () {
      let items = [];
      if (this.bet) {
        items.push({
          label: `&lt; back to ${this.guild ? this.guild.name : "guild"}`,
          to: `/guild/${this.guild.id}`,
          active: true,
        });

        if (this.isModerator) {
          items.push({
            label: `Moderator panel`,
            to: `/bets/${this.bet.id}/mod`,
            active: false,
          });
        }
      }

      return items;
    },
    optionStyles: function () {
      return (option) => {
        let styles = [];
        if (this.selectedOption == option) {
          styles.push("bg-gray-200");
        }
        return styles.join(" ");
      };
    },
    selectedOption: function () {
      return this.bet?.myEntry?.option;
    },
    entries: function () {
      return (option) => {
        return this.bet.entries.filter((e) => e.option == option);
      };
    },
    odds: function () {
      return this.entries("a").length + ":" + this.entries("b").length;
    },
    canEditEntry: function () {
      return this.bet && this.bet.status == "waiting";
    },
    possibleWins: function () {
      let entriesA = this.entries("a").length;
      let entriesB = this.entries("b").length;

      if (this.entries("a").length == 0 && this.entries("b").length == 0) {
        return [0, 0];
      } else if (this.entries("a").length == 0) {
        return [this.entries("b").length * 3.5, 0];
      } else if (this.entries("b").length == 0) {
        return [0, this.entries("a").length * 3.5];
      } else {
        return [
          formatCredit((entriesB * 3.5) / entriesA),
          formatCredit((entriesA * 3.5) / entriesB),
        ];
      }
    },
  },
  methods: {
    load: function (force) {
      let payload = {
        id: this.$route.params.id,
        force: force,
      };
      this.$store.dispatch("bet/load", payload).then((bet) => {
        this.$router.updateMeta({ title: [bet.subject, this.guild?.name] });
      });
    },
    select: function (option) {
      if (!this.isLoggedIn) {
        this.$store.dispatch("user/openLoginModal");
      } else if (!this.isMember) {
        this.showMemberBet = true;
      } else if (this.canEditEntry) {
        this.showMemberBet = false;

        if (this.selectedOption && this.selectedOption == option) {
          this.$store.dispatch("bet/deleteEntry");
          this.mixpanel.track("entry_delete", {
            guildId: this.guild.id,
            betId: this.bet.id,
          });
        } else if (this.selectedOption && this.selectedOption != option) {
          this.$store.dispatch("bet/changeEntry", option);
          this.mixpanel.track("entry_change", {
            guildId: this.guild.id,
            betId: this.bet.id,
            option: option,
          });
        } else {
          this.$store.dispatch("bet/entry", option);
          this.mixpanel.track("entry_create", {
            guildId: this.guild.id,
            betId: this.bet.id,
            option: option,
          });
        }
      }
    },
    formatDate: function (date) {
      let m = moment(date);
      return m.format("YYYY-MM-DD HH:mm");
    },
  },
  watch: {
    isLoggedIn: function () {
      this.load(true);
    },
  },
  mounted: function () {
    //this.bet.id = this.$route.params.id;
    this.load();
  },
  components: {
    GuildsDetailHeader,
    LockIcon,
    FlagCheckeredIcon,
    OpenInNewIcon,
    CashMultipleIcon,
    AccountGroupIcon,
    MenuTop,
  },
};
</script>
