import { API, Auth } from 'aws-amplify';
import store from '@/store'

const API_NAME = 'treefiddyapi';

function loaderInc() {
    store.commit('loader/inc');
}

function loaderDec() {
    store.commit('loader/dec');
}

function returnResult(result) {
    loaderDec();
    return result;
}

function throwError(error) {
    loaderDec();
    //store.commit('notify/error', error);
    return Promise.reject(error);
}

async function createConfig(defaultValue) {
    let token = await Auth.currentSession()
                    .then(user => user.getIdToken().getJwtToken())
                    .catch(() => null);
    
    let config = defaultValue || {};
    if (!token) token = 'anonymous';
    if (token) {
        config.headers = { 
            Authorization: `Bearer ${token}`,
        }
    }
    return config;
}

export async function get(path, queryStringParameters) {
    let config = await createConfig({
        queryStringParameters: queryStringParameters
    });
    loaderInc();
    return await API.get(API_NAME, path, config)
        .then(result => returnResult(result))
        .catch(error => throwError(error))
;
}

export async function post(path, data) {
        let config = await createConfig({
            body: data
        });
        loaderInc();
        return await API.post(API_NAME, path, config)
            .then(result => returnResult(result))
            .catch(error => throwError(error))

}
export async function patch(path, data) {
    let config = await createConfig({
        body: data
    });
    loaderInc();
    return await API.patch(API_NAME, path, config)
        .then(result => returnResult(result))
        .catch(error => throwError(error))

}

export async function del(path, data) {
    let config = await createConfig({
        body: data
    });
    loaderInc();
    return await API.del(API_NAME, path, config)
        .then(result => returnResult(result))
        .catch(error => throwError(error))

}