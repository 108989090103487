<template>
  <div class="home">
    <GuildsDetailHeader v-if="guild" :guild="guild" />

    <div v-if="guild && isAdmin" class="p-4">
      <UiPanel label="Group settings" class="mt-4">
        <GuildsEditForm />
      </UiPanel>
      <UiPanel label="Cover image" class="mt-4">
        <GuildsEditImage />
      </UiPanel>

      <UiPanel label="Users" class="mt-4" :flex="false">
        <GuildsUsers />
      </UiPanel>

      <UiPanel label="Archive group" class="mt-4" :flex="false">
        <GuildsGenerateAchievements />
        <GuildsArchive />
      </UiPanel>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from "vuex";

import GuildsDetailHeader from "../components/Guilds/GuildsDetailHeader.vue";
import UiPanel from "../components/Ui/UiPanel.vue";
import GuildsUsers from "../components/Guilds/GuildsUsers.vue";
import GuildsEditForm from "../components/Guilds/GuildsEditForm.vue";
import GuildsArchive from "../components/Guilds/GuildsArchive.vue";
import GuildsEditImage from "../components/Guilds/GuildsEditImage.vue";
import GuildsGenerateAchievements from "../components/Guilds/GuildsGenerateAchievements.vue";

export default {
  name: "GuildEdit",
  components: {
    GuildsDetailHeader,
    UiPanel,
    GuildsUsers,
    GuildsEditForm,
    GuildsArchive,
    GuildsEditImage,
    GuildsGenerateAchievements,
  },
  data: () => ({
    archiveConfirm: null,
  }),
  computed: {
    ...mapState("guild", {
      guild: (state) => state.guild,
    }),
    ...mapGetters("guild", ["isAdmin"]),
  },
  methods: {
    load: function () {
      this.$store.dispatch("guild/load", this.$route.params.id);
    },
  },
  watch: {
    $route: function (newVal) {
      if (newVal.name == "GuildEdit")
        this.$store.dispatch("guild/load", this.$route.params.id);
    },
  },
  mounted: function () {
    this.load();
  },
};
</script>
