import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import BetCreate from '../views/BetCreate.vue'
import GuildCreate from '../views/GuildCreate.vue'
import GuildDetail from '../views/GuildDetail.vue'
import GuildEdit from '../views/GuildEdit.vue'
import BetDetail from '../views/BetDetail.vue'
import BetModerate from '../views/BetModerate.vue'
import BetCreateTampermonkey from '../views/BetCreateTampermonkey'
import GuildArchive from '@/views/GuildArchive'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/archive',
    name: 'GuildArchive',
    component: GuildArchive
  },
  {
    path: '/tm',
    name: 'BetCreateTampermonkey',
    component: BetCreateTampermonkey
  },
  {
    path: '/create-guild',
    name: 'GuildCreate',
    component: GuildCreate
  },
  {
    path: '/guild/:id',
    name: 'GuildDetail',
    component: GuildDetail
  },
  {
    path: '/guild/:id/edit',
    name: 'GuildEdit',
    component: GuildEdit
  },
  {
    path: '/bets/:id/mod',
    name: 'BetModerate',
    component: BetModerate
  },
  {
    path: '/bets/:id',
    name: 'BetDetail',
    component: BetDetail,
    meta: {
      title: 'xx'
    }
  },
  {
    path: '/guild/:id/new',
    name: 'BetCreate',
    component: BetCreate
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = 'Tree Fiddy Club';

router.updateMeta = function(meta) {
  nextTick(() => {
    console.log(typeof meta.title);
    if (meta.title) {
      if (typeof meta.title === 'object') {
        meta.title = meta.title
          .filter((v) => v)
          .join(" | ");
      }
      
      document.title = `${meta.title} | ${DEFAULT_TITLE}` || DEFAULT_TITLE;
      
    }

    document.title = `${meta.title} | ${DEFAULT_TITLE}` || DEFAULT_TITLE;
});
}

export default router
