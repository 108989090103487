<template>
  <div class="home p-4">
    <h1>Explore groups</h1>
    <GuildsList />
    <!--<a href="#" @click.prevent="sync">SYNC</a>-->
  </div>
</template>

<script>
// @ is an alias to /src

import GuildsList from "../components/Guilds/GuildsList.vue";

export default {
  name: "Home",
  data: () => ({
    bets: [],
    guilds: [],
  }),
  components: {
    GuildsList,
  },
  methods: {
    load: function () {},
    sync: function () {
      this.$api.get("/bets/sync");
    },
  },
  mounted: function () {
    this.load();
  },
};
</script>
