<template>
  <div
    class="flex items-center border-b last:border-0 relative cursor-pointer hover:bg-gray-100"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div class="w-2/3 p-4">
      <div class="text-left text-xl truncate flex items-center">
        {{ bet.subject }}
        <span
          v-if="bet.status == 'waiting' && !selectedOption"
          class="text-xs bg-green-400 text-white px-1 rounded ml-2"
          >new</span
        >
      </div>
      <div class="text-xs">
        <span v-if="bet.status == 'finished'">
          <CheckIcon
            v-if="won"
            :size="10"
            class="inline-block text-green-400"
          />
          <CancelIcon
            v-else-if="lost"
            :size="10"
            class="inline-block text-red-400"
          />
          <CheckIcon v-else :size="10" class="inline-block" />
          Finished {{ finishedAgo }}
        </span>
        <span v-else-if="bet.status == 'closed'"
          ><ClockOutlineIcon :size="10" class="inline-block" /> Waiting for
          finish, closed {{ closedAgo }}</span
        >
        <span v-else>
          <ClockOutlineIcon :size="10" class="inline-block" /> Closing in
          {{ closeAt }}
        </span>
      </div>
    </div>

    <div
      v-if="bet.status == 'finished'"
      :class="`w-1/3 p-2 text-center border-l flex-col h-full flex items-center justify-around bg-${finalOptionColor}`"
    >
      <div v-if="won" class="underline font-bold">{{ finalOptionText }}</div>
      <div v-else>{{ finalOptionText }}</div>
      <div v-if="selectedOption" class="basis-full">
        <CashMultipleIcon :size="12" class="inline-block" />
        {{ formatCredit(credit) }}
      </div>
    </div>

    <div
      v-else
      class="w-1/3 text-center border-l h-full flex flex-row items-center justify-around relative"
    >
      <div
        :class="
          'truncate w-1/2 p-2 pr-3 text-center' +
          (selectedOption == 'a' ? 'underline font-bold' : '')
        "
      >
        {{ bet.optionA }}
      </div>
      <div class="relative w-px bg-gray-200 h-full"></div>
      <div class="absolute bg-white border rounded-full text-xs w-5 h-5">
        vs
      </div>

      <div
        :class="
          'truncate w-1/2 p-2 pl-3 text-center' +
          (selectedOption == 'b' ? 'underline font-bold' : '')
        "
      >
        {{ bet.optionB }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
require("moment-countdown");

import ClockOutlineIcon from "vue-material-design-icons/ClockOutline.vue";
import CheckIcon from "vue-material-design-icons/Check.vue";
import CancelIcon from "vue-material-design-icons/Cancel.vue";
import CashMultipleIcon from "vue-material-design-icons/CashMultiple.vue";
import { formatCredit } from "@/lib/formatUtil";

export default {
  name: "BetListItem",
  components: {
    ClockOutlineIcon,
    CheckIcon,
    CancelIcon,
    CashMultipleIcon,
  },
  props: {
    bet: Object,
  },
  data: () => ({
    hover: false,
    auth: null,
    closeAt: null,
  }),
  computed: {
    optionStyles: function () {
      return (option) => {
        let styles = [];
        if (this.selectedOption == option) {
          styles.push("bg-gray-200");
        }
        return styles.join(" ");
      };
    },
    selectedOption: function () {
      let option = null;
      if (this.bet && this.bet.entries && this.bet.entries.length > 0)
        return this.bet.entries[0].option;
      return option;
    } /*
    closeAt: function () {
      let m = moment(this.bet.closeAt);
      return m.countdown().toString();
      //return m.to(moment(), true);
    },*/,
    finalOptionText: function () {
      return this.bet[`option${this.bet.finalOption.toUpperCase()}`];
    },
    finalOptionColor: function () {
      let color = "gray-200";

      if (this.selectedOption && this.bet.finalOption == this.selectedOption)
        color = "green-400";
      if (this.selectedOption && this.bet.finalOption != this.selectedOption)
        color = "red-400";
      return color;
    },
    finishedAgo: function () {
      let m = moment(this.bet.finishAt);
      return m.fromNow();
    },
    closedAgo: function () {
      let m = moment(this.bet.closeAt);
      return m.fromNow();
    },
    won: function () {
      return this.selectedOption && this.bet.finalOption == this.selectedOption;
    },
    lost: function () {
      return this.selectedOption && this.bet.finalOption != this.selectedOption;
    },
    credit: function () {
      if (this.bet && this.bet.entries && this.bet.entries.length > 0)
        return this.bet.entries[0].credit;
      return 0;
    },
  },
  methods: {
    select: function (option) {
      if (!this.selectedOption)
        this.$api.post(`/bets/${this.bet.id}/entries`, {
          option: option,
        });
    },
    formatCredit: formatCredit,
    closeAtUpdate: function () {
      let m = moment(this.bet.closeAt);
      this.closeAt = m.countdown().toString();
    },
  },
  mounted() {
    setInterval(() => this.closeAtUpdate(), 1000);
  },
};
</script>
