<template>
  <div class="w-full">
    <div
      v-for="type in sortedAchievements"
      :key="type.id"
      class="flex pb-5 items-center"
    >
      <img
        :src="`/img/achievement/${type.image}`"
        :alt="type.name"
        :title="type.name"
        class="rounded-full shadow-lg w-16"
      />
      <div class="pl-5 text-sm">
        <div class="text-gray-400">{{ type.name }}</div>
        <div v-if="type.achievements.length > 0">
          {{ achievementNames(type.achievements) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "GuildsAchievements",
  components: {},
  data: () => ({}),
  computed: {
    ...mapState("user", {
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user,
    }),
    ...mapGetters("guild/achievements", ["sortedAchievements"]),
  },
  methods: {
    creditClass: function (credit) {
      if (credit < 0) return "text-red-600";
      else return "";
    },
    achievementNames: function (achievements) {
      return achievements.map((a) => a.user.display_name).join(", ");
    },
  },
  mounted: function () {
    this.$store.dispatch("guild/achievements/load");
  },
};
</script>
