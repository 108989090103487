<template>
    <div class="flex p-4 pb-0 bg-gray-100 ">
        <div v-for="(item, index) in items" :key="index" :class="'grow px-8 py-2 bg-gray-300 mr-4' + (item.active ? ' border-b-4 border-green-400' : '')">
            <router-link :to="item.to" v-html="item.label"></router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MenuTop',
        props: ['items']
    }
</script>