<template>
  <div class="w-full">
    <table class="w-full">
      <thead>
        <tr>
          <th class="text-left"></th>
          <th class="text-left"></th>
          <th><TrophyIcon :size="16" class="inline-block text-green-600" /></th>
          <th><TrophyIcon :size="16" class="inline-block text-red-600" /></th>
          <th class="text-center">
            <CashMultipleIcon :size="16" class="inline-block" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(standing, index) in sortedStandings" :key="standing.id">
          <td>{{ index + 1 }}.</td>
          <td class="text-left">{{ standing.user.display_name }}</td>
          <td class="text-center text-green-600">{{ standing.won }}</td>
          <td class="text-center text-red-600">{{ standing.lost }}</td>
          <td :class="`text-right ` + creditClass(standing.credit)">
            {{ formatCredit(standing.credit) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TrophyIcon from "vue-material-design-icons/Trophy.vue";
import CashMultipleIcon from "vue-material-design-icons/CashMultiple.vue";
import { formatCredit } from "@/lib/formatUtil";

export default {
  name: "GuildsStanding",
  components: {
    TrophyIcon,
    CashMultipleIcon,
  },
  data: () => ({}),
  computed: {
    ...mapState("user", {
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user,
    }),
    ...mapGetters("guild/standings", ["sortedStandings"]),
  },
  methods: {
    formatCredit: formatCredit,
    creditClass: function (credit) {
      if (credit < 0) return "text-red-600";
      else return "";
    },
  },
  mounted: function () {
    this.$store.dispatch("guild/standings/load");
  },
};
</script>
